<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center" class="mb-2">
        <v-col cols="auto" class="pr-12">
          <h1>Gift Vouchers</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Gift Vouchers"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  class="mr-2"
                  @click="$refs.giftVoucherDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create New Gift Voucher</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="vouchers"
        class="mt-4"
        no-data-text="No Voucher found"
      >
        <template v-slot:item.customer="{ item }">
          <router-link
            :to="{
              name: 'module-arnprior-customers-individual',
              params: { customerId: item.order.customer_id },
            }"
            >{{ item.order.customer.full_name }}</router-link
          >
        </template>
        <template v-slot:item.amount="{ item }"> £{{ item.amount }} </template>
        <template v-slot:item.remaining_amount="{ item }">
          £{{ item.remaining_amount }}
        </template>
        <template v-slot:item.valid_to="{ item }">
          {{ formatDate(item.valid_to.substring(0, 10)) }}
        </template>
        <template v-slot:item.valid_from="{ item }">
          {{ formatDate(item.valid_from.substring(0, 10)) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.giftVoucherDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openGiftVoucherDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="deleteGiftVoucher.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Gift Voucher</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteGiftVoucher.giftVoucher.code }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetGiftVoucherDelete"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteGiftVoucher.loading"
            @click="saveGiftVoucherDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <gift-voucher-dialog ref="giftVoucherDialog" />
  </div>
</template>

<script>
import GiftVoucherDialog from "@/modules/arnprior/views/vouchers/components/GiftVoucherDialog";

export default {
  components: {
    GiftVoucherDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Orders",
          disabled: true,
        },
      ],
      searchTerm: "",
      tableHeaders: [
        { text: "Customer", value: "customer" },
        { text: "Recipient", value: "recipient_name" },
        { text: "Total Amount", value: "amount" },
        { text: "Remaining Amount", value: "remaining_amount" },
        { text: "Code", value: "code" },
        { text: "Valid From", value: "valid_from" },
        { text: "Valid Until", value: "valid_to" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteGiftVoucher: {
        dialog: false,
        giftVoucher: {},
        loading: false,
      },
    };
  },

  methods: {
    openGiftVoucherDelete(giftVoucher) {
      this.deleteGiftVoucher.giftVoucher = giftVoucher;
      this.deleteGiftVoucher.dialog = true;
    },

    resetGiftVoucherDelete() {
      this.deleteGiftVoucher.dialog = false;
      this.deleteGiftVoucher.giftVoucher = {};
      this.deleteGiftVoucher.loading = false;
    },

    saveGiftVoucherDelete() {
      this.deleteGiftVoucher.loading = true;

      this.$store
        .dispatch("arnprior/vouchersStore/deleteGiftVoucher", {
          appId: this.$route.params.id,
          giftVoucherId: this.deleteGiftVoucher.giftVoucher.id,
        })
        .then(() => {
          this.resetGiftVoucherDelete();
        })
        .catch(() => {
          this.deleteGiftVoucher.loading = false;
        });
    },
  },

  computed: {
    vouchers() {
      let vouchers = this.$store.getters["arnprior/vouchersStore/vouchers"];

      if (this.searchTerm !== "") {
        vouchers = vouchers.filter((c) => {
          const code = c.code.toLowerCase();
          const customerName = c.order.customer.full_name.toLowerCase();
          const customerEmail = c.order.customer.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            code.includes(searchTerm) ||
            customerName.includes(searchTerm) ||
            customerEmail.includes(searchTerm)
          );
        });
      }

      return vouchers;
    },
  },
};
</script>
